exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-blogs-aidatasafety-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/src/blogs/aidatasafety/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-blogs-aidatasafety-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-blogs-aiimprov-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/src/blogs/aiimprov/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-blogs-aiimprov-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-blogs-dadai-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/src/blogs/dadai/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-blogs-dadai-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-blogs-vaderai-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/src/blogs/vaderai/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-blogs-vaderai-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-blogs-vsmacretirement-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/github/workspace/src/blogs/vsmacretirement/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-blogs-vsmacretirement-index-mdx" */)
}

